import axios from '@/plugins/axiosV2'

const http = axios(process.env.VUE_APP_API_CONDUCEF + '/consultas');

export default {

    listar() {
        return http.get('/');
    },

    crear(payload) {
        return http.post('/', payload);
    },

}