<template>
  <div>
    <Modal >
      <form @submit.prevent="onSubmit">
        <div class="title"> {{ !consulta.numero_folio ? 'Crear nueva consulta' : 'Información de la consulta' }}</div>

        <div v-if="!consulta.numero_folio">
          <div class="alert alert-primary mx-3 my-3" role="alert">
            <i class="fa-solid fa-circle-info"></i>
            Para garantizar que la generación de consulta se procese adecuadamente, por favor, asegúrate de proporcionar
            información completa y precisa en todos los campos del formulario.
          </div>
        </div>

        <div class="body">

          <div  v-if="pagina === 1">
            <h5>
              Información general
              <br>
              <small class="text-secondary">(Paso 1/2)</small>
            </h5>

            <div class="row justify-center">


              <div class="col-md-4 my-3">
                <label for="trimestre_presentar">Trimestre</label>
                <select v-model="consulta.trimestre" name="trimestre_presentar" id="trimestre_presentar"
                        class="form-control" :disabled="!editable">
                  <option :value="num_trimestre" v-for="(trimestre, num_trimestre) of trimestres">{{trimestre}}</option>
                </select>
              </div>

              <div class="col-md-4 my-3">
                <label for="año_presentar">Año</label>
                <select v-model="consulta.trimestre_ano" name="año_presentar" id="año_presentar"
                        class="form-control" :disabled="!editable">
                  <option :value="ano_actual-1">{{ano_actual-1}}</option>
                  <option :value="ano_actual">{{ano_actual}}</option>
                </select>
              </div>

              <div class="col-md-4 my-3">
                <button type="button" class="btn btn-link btn-sm" data-toggle="popover" title="De acuerdo a la disposición siempre es el valor de “1”"><i class="fa fa-info-circle"></i></button>
                <label for="numero_consultas">Número de consulta</label>
                <input v-model="consulta.numero_consultas" type="text" name="numero_consultas" id="numero_consultas"
                       class="form-control" disabled>
              </div>

              <div class="col-md-6 my-3">
                <label for="numero_folio">Número de folio de consulta</label>
                <input id="numero_folio" type="text" class="form-control" v-model="consulta.numero_folio" :disabled="!editable" maxlength="36">
              </div>

              <div class="col-md-6 my-3">
                <label for="fecha_recepcion">Fecha de recepción</label>
                <input v-model="consulta.fecha_recepcion" type="date" name="fecha_recepcion"
                       id="fecha_recepcion" class="form-control" :disabled="!editable">
              </div>


              <div class="col-md-6 my-3">
                <label for="medio_de_recepcion">Medio de recepción</label>
                <select v-model="consulta.medio_de_recepcion" name="medio_de_recepcion"
                        id="medio_de_recepcion" class="form-control" :disabled="!editable">
                  <option v-for="medio in medios_recepciones" :value="medio.valor" :key="medio.valor">
                    {{ medio.nombre }}
                  </option>
                </select>
              </div>

              <div class="col-md-6 my-3">
                <label for="prioridad">Prioridad</label>
                <select v-model="consulta.prioridad" name="prioridad" id="prioridad" class="form-control"
                        :disabled="!editable">
                  <option value="SI">Si</option>
                  <option value="NO">No</option>
                </select>
              </div>

              <div class="col-md-6 my-3">
                <label for="producto_servicio">Producto servicio</label>
                <select v-model="consulta.producto_servicio" name="producto_servicio"
                        id="producto_servicio" class="form-control" :disabled="!editable">
                  <option v-for="producto in productos_servicios" :value="producto.valor"
                          :key="producto.valor">
                    {{ producto.nombre }}
                  </option>
                </select>
              </div>

              <div class="col-md-6 my-3">
                <label for="causa">Causa o motivo</label>
                <select v-model="consulta.causa" name="causa" id="causa"
                        class="form-control" :disabled="!editable">
                  <option v-for="causa in causas_financieras" :value="causa.valor">
                    {{ causa.nombre }}
                  </option>
                </select>
              </div>

              <div class="col-md-6 my-3">
                <label for="estatus_consulta">Estado de la consulta</label>
                <select v-model="consulta.estatus_consulta" name="estatus_consulta" id="estatus_consulta"
                        class="form-control" :disabled="!editable" @change="cambio_estatus_consulta()">
                  <option value="1">Pendiente</option>
                  <option value="2">Concluido</option>
                </select>
              </div>

              <div class="col-md-6 my-3">
                <button type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El campo solo es requerido cuando el estatús de consulta es concluido"><i class="fa fa-info-circle"></i></button>
                <label for="fecha_atencion">Fecha de atención</label>
                <input v-model="consulta.fecha_atencion" type="date" name="fecha_atencion"
                       id="fecha_atencion" class="form-control" :disabled="!editable">
              </div>

              <div class="col-md-6 my-3">
                <button type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El campo solo es requerido cuando el estatús de consulta es concluido"><i class="fa fa-info-circle"></i></button>
                <label for="nivel_atencion">Nivel de atención</label>
                <select v-model="consulta.nivel_atencion" name="nivel_atencion"
                        id="nivel_atencion" class="form-control" :disabled="!editable">
                  <option v-for="nivel in niveles_atenciones" :value="nivel.valor" :key="nivel.valor">
                    {{ nivel.nombre }}
                  </option>
                </select>
              </div>

            </div>
          </div>

          <div v-else-if="pagina === 2">


            <h5>
              Detalles de la consulta
              <br>
              <small class="text-secondary">(Paso 2/2)</small>
            </h5>


            <div class="row justify-center">

              <div class="col-md-6 my-3">
                <button type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El campo es requerido solo si el medio de recepción es UNE, Sucursal u Oficina de atención al público"><i class="fa fa-info-circle"></i></button>
                <label for="codigo_postal">Codigo postal</label>
                <input v-model="consulta.codigo_postal" @blur="buscar_codigo_postal" type="text"
                       maxlength="5" name="codigo_postal" id="codigo_postal" class="form-control"
                       :disabled="!editable">
              </div>

              <div class="col-md-6 my-3">
                <label for="estado">Estado</label>
                <select v-model="consulta.estado" name="estado" id="estado" class="form-control"
                        :disabled="!editable">
                  <option v-for="estado in estados" :value="estado.valor" :key="estado.valor">
                    {{ estado.nombre }}</option>
                </select>
              </div>


              <div class="col-md-6 my-3">
                <label for="entidad">Delegación / Municipio</label>
                <select v-model="consulta.entidad" name="entidad" id="entidad" class="form-control"
                        :disabled="!editable">
                  <option v-for="municipio in municipios" :value="municipio.valor" :key="municipio.valor">
                    {{ municipio.nombre }}
                  </option>
                </select>
              </div>

              <div class="col-md-6 my-3">
                <label for="colonia">Colonia</label>
                <select v-model="consulta.colonia" name="colonia" id="colonia" class="form-control"
                        :disabled="!editable">
                  <option v-for="colonia in colonias" :value="colonia.valor" :key="colonia.valor">
                    {{ colonia.nombre }}
                  </option>
                </select>
              </div>


              <div class="col-md-6 my-3">
                <label for="localidad">Localidad</label>
                <select v-model="consulta.localidad" name="localidad" id="localidad" class="form-control"
                        :disabled="!editable">
                  <option v-for="localidad in localidades" :value="localidad.valor"
                          :key="localidad.valor">{{ localidad.nombre }}
                  </option>
                </select>
              </div>

            </div>
          </div>

        </div>

        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-secondary mr-2" type="button" @click="anterior"
                      :disabled="pagina === 1">
                <i class="fa-solid fa-arrow-left"></i>
                Anterior
              </button>
              <button class="btn btn-primary mr-2" type="button" @click.prevent="show_modal_confirmar_crear = true"
                      v-if="(pagina === 2 && !consulta.id) || (consulta.id && evento_id)">
                <i class="fa-solid fa-floppy-disk"></i>
                {{ evento_id ? 'Actualizar' : 'Guardar' }}
              </button>

              <button class="btn btn-secondary mr-2" type="button" @click="siguiente"
                      :disabled="pagina === 2" v-if="pagina !== 2">
                Siguiente
                <i class="fa-solid fa-arrow-right"></i>
              </button>

              <br>
              <button class="btn btn-danger mt-3 mr-2" type="button" @click.prevent="$emit('close')">
                <i class="fa-solid fa-xmark"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>

      </form>

      <!--Modal Confirmar datos -->
      <Modal v-if="show_modal_confirmar_crear" :options="{ width: '30vw', type: 'danger' }">
        <div class="title"> <i class="fa fa-warning mr-2"></i> Mensaje importante</div>
        <div class="body">
          <h5 class="font-weight-bold">
            ¿Estás seguro de que todos los datos han sido cargados correctamente en el formulario?
          </h5>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2" type="button" @click.prevent="onSubmit">
                <i class="fa-solid fa-check"></i>
                Confirmar
              </button>
              <button class="btn btn-danger" type="button" @click.prevent="show_modal_confirmar_crear = false">
                <i class="fa-solid fa-xmark"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <!--Modal Confirmar datos  -->

    </Modal>
  </div>
</template>

<script>
import apiCatalogos from '@/apps/condusef/api/catalogos';
import apiSepomex from '@/apps/condusef/api/sepomex';
import apiCondusefConsultas from '@/apps/condusef/api/consultas';
import Modal from '@/components/Modal';

export default {
  components: { Modal },
  props: {
    prop_consulta: {
      type: Object,
      default() {
        return {
          numero_folio: null
        }
      }

    },
  },
  data() {
    return {
      consulta: {
        numero_folio: null,
        trimestre: null,
        trimestre_ano: null,
        numero_consultas: 1,
        estatus_consulta: null,
        prioridad: null,
        nivel_atencion: null,
        fecha_atencion: null,
        fecha_recepcion: null,
        medio_de_recepcion: null,
        producto_servicio: null,
        causa: null,
        codigo_postal: null,
        estado: null,
        entidad: null,
        colonia: null,
        localidad: null,
      },
      editable: false,
      show_editar: null,
      evento_id: null,
      pagina: 1,
      medios_recepciones: [],
      productos_servicios: [],
      causas_financieras: [],
      niveles_atenciones: [],
      colonias: [],
      estados: [],
      municipios: [],
      localidades: [],
      show_modal_confirmar_crear: false,
      estatus_concluido: 2,
      trimestres:  {1:'ENERO-MARZO', 2:'ABRIL-JUNIO', 3:'JULIO-SEPTIEMBRE', 4:'NOVIEMBRE-DICIEMBRE'}

    }
  },
  computed: {
    ano_actual(){
      return this.$moment().year()
    },
    //El trimestre que se debe presentar siempre va a ser el anterior al actual
    trimestre_activo() {
      if (this.prop_consulta.numero_folio)
        return {trimestre: this.prop_consulta.trimestre,  trimestre_ano: this.prop_consulta.trimestre_ano, label: this.prop_consulta.trimestre_label}


      const trimestre_actual = this.$moment().quarter()

      if (trimestre_actual === 1) {
        const trimestre_ano = this.ano_actual - 1;
        return {trimestre: 4, trimestre_ano: trimestre_ano, label: `${this.trimestres[4]} ${trimestre_ano}`}
      }

      const trimestre_activo = (trimestre_actual-1);

      return {
        trimestre: trimestre_activo, trimestre_ano: this.ano_actual,  label: `${this.trimestres[trimestre_activo]} ${this.ano_actual}`

      }
    }
  },
  mounted() {
    if (this.prop_consulta.numero_folio) {
      this.consulta = this.$helper.clone(this.prop_consulta);
      this.editable = false;
      this.buscar_codigo_postal()
    } else {
      this.editable = true
      this.consulta.trimestre = this.trimestre_activo.trimestre
      this.consulta.trimestre_ano = this.trimestre_activo.trimestre_ano
    }

    this.obtener_catalogos();
  },
  methods: {
    anterior() {
      if (this.pagina > 1) {
        this.pagina--;
      }
    },
    siguiente() {
      if (this.pagina < 2) {
        this.pagina++;
      }
    },

    cambio_estatus_consulta(){
      // si el esaatus no esconcluido los siguientes campos no son requeridos (se pasán a nulo)
      if (this.consulta.estatus_consulta !== this.estatus_concluido)
        this.consulta.fecha_atencion = null
      this.consulta.nivel_atencion = null
    },
    async obtener_catalogos() {
      try {
        const catalogos = (await apiCatalogos.listar_catalogos_reune()).data;
        for (const catalogo of catalogos) {
          if (catalogo.codigo == 'medios_de_recepcion')
            this.medios_recepciones = catalogo.opciones;
          else if (catalogo.codigo == 'lista_de_productos_consultas_reune')
            this.productos_servicios = catalogo.opciones;
          else if (catalogo.codigo == 'niveles_de_atencion')
            this.niveles_atenciones = catalogo.opciones;
        }
      } catch (error) {
        this.$log.error('error', error);
        this.$helper.showAxiosError(error, 'Error');
      }
    },

    async buscar_codigo_postal() {
      try {
        const response = (await apiSepomex.codigos_postales(this.consulta.codigo_postal)).data;
        this.colonias = response.colonias
        this.estados = response.estados
        this.municipios = response.municipios
        this.localidades = response.localidades
      } catch (error) {
        this.$log.error('error', error);
        this.$helper.showAxiosError(error, 'Error');
      }
    },
    async obtener_casuas(){

      this.causas_financieras = []

      const url_options = {
        filters: [{
          relacion: "and",
          columna: "codigo",
          operador: "equal_to",
          valor1: 'reune_consultas_causas_producto_'+this.consulta.producto_servicio,
          valor2: null
        }]
      }
      const response = (await apiCatalogos.listar_catalogos(url_options)).data[0]
      this.causas_financieras = response.opciones

    },
    async onSubmit() {

      this.show_modal_confirmar_crear = false;
      try {
        const response = (await apiCondusefConsultas.crear(this.consulta)).data
        this.$helper.showMessage('Crear Queja', 'Consulta guardada exitosamente.', 'success', 'alert')

        this.$log.info('response', response);
        this.$emit('update');
      } catch (error) {
        this.$log.error('error', error);
        this.$helper.showAxiosError(error, 'Error');
      }
    },
  },

  watch: {
    'consulta.producto_servicio': {
      handler(newValue, oldValue) {
        if (!!newValue && newValue !== oldValue) {

          if (oldValue !== null)
            this.consulta.causa = null

          this.obtener_casuas()
        }
      }
    }
  }

};

</script>